import React from "react"
import { ColorPalette } from "../Theme"

/** const */
const HeroSustainableSection: React.FC = () => {
  return (
    <div>
      <img
        src="/sustainable/norhart-sustainable.png"
        alt="Norhart Sustainable Apartments"
        loading="eager"
        className="img-fluid w-100"
      />
    </div>
  )
}

/** export */
export default HeroSustainableSection
